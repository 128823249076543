import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { getPrivacyPolicy } from '../contentful';
import marked from 'marked';

const Privacy = (props) => {
    const [policy, setPolicy] = useState([])

    const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)'
        }
      };

    useEffect(() => {
        getPrivacyPolicy().then(data => setPolicy(data))
    }, []

    )

    return(
        <Modal 
        isOpen={props.open}
        style={{overlay: {backgroundColor: 'rgba(0,0,0,.5)'}}}
        closeTimeoutMS={200}

        >        
        <div className="contents">
            <h4>Privacy Policy</h4>
            {policy.map(text => {
                const content = marked(text.fields.content)
                return <div>
                     <div dangerouslySetInnerHTML={{__html: content}} ></div>
                     <a onClick={props.close} className="btn btn-dark" href="#">close</a>
                     </div>
            })}

        </div>
        </Modal>
    )
}

export default Privacy;
