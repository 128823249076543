import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'
import  ShopProvider  from './context/ShopContext' 
import Cart from './components/Cart'




import Main from './components/Main';
import Store from './pages/Store';
import Contact from './components/Contact';
import Nav from './components/Nav1';
import Posts from './components/Posts'
import Footer from './components/Footer'
import Media from './components/Media'
import Calendar from './components/Calendar'
import Gallery from './components/Gallery'
import Locations from './components/Locations'
import Blends from './components/BespokeBlends'
import Product from './pages/Product';
import BiogFull from './components/BiographyFull'
import SinglePost from './components/SinglePost'
import OurStory from './components/OurStory'
import OurCoffees from './components/OurCoffees'
import Review from './components/ReviewFull'
import FootButtons from './components/FootButtons'
import CoffeeClub from './components/CoffeeClub'
import CoffeeCorner from './components/CoffeeCorner'
import SideDrawer from './components/SideDrawer/SideDrawer'
import Toolbar from './components/Toolbar/Toolbar'
import Backdrop from './components/Backdrop/Backdrop'



const engine = new Styletron();


class App extends Component {

    state ={
      sideDrawerOpen: false
    };
  

  


  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen};
    });
  };

  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false})
  }

  render(){
    let backdrop;

    if(this.state.sideDrawerOpen){
      backdrop = <Backdrop click={this.backdropClickHandler}/>
    }
  return (
    <ShopProvider>
    <StyletronProvider value={engine}  >
    <Router>
    <Toolbar  drawerClickHandler={this.drawerToggleClickHandler} />
      <SideDrawer show={this.state.sideDrawerOpen} click={this.drawerToggleClickHandler}/>
      {backdrop}

      <main style={{marginTop:'56px'}}>
      </main>  
    <Cart/>
    <Switch>
    <Route path="/store" component={Store}/>
    <Route path="/product/:id" component={Product}/>
    <Route path='/locations' component={Locations}/>
    <Route path="/contact" component={Contact}/>
    <Route path="/posts/reviews/:id" component={Review}/>
    <Route path="/posts/:id" component={SinglePost}/>
    <Route path="/posts" component={Posts}/>
    <Route path="/blends" component={Blends}/>
    <Route path="/ourstory" component={OurStory} />
    <Route path="/biography" component={BiogFull}/>
    <Route path="/gallery" component={Gallery}/>
    <Route path="/ourcoffees" component={OurCoffees} />

    <Route path="/coffeecorner" component={CoffeeCorner} />

    <Route path="/coffeeclub" component={CoffeeClub} />
    <Route exact path="/" component={Main}/>

    </Switch>

  
       <FootButtons/>
        <Footer/>
    </Router>

    </StyletronProvider>
    </ShopProvider>
  );
  }
}
export default App;
