import React, { useState, useEffect } from 'react'
import { getBlogPosts, getReviews } from '../contentful'
import BlogItems from './BlogItems'
import TitleBar from './TitleBar'
import Reviews from './Reviews'

const Posts = (props) => {
  
const [posts, setPosts] = useState([])
  useEffect(() => {
    getBlogPosts().then(data => setPosts(data))}, []
  )
  
  
   
     return (
      
        <div className='contents'>
          <TitleBar title="News & Reviews" />
      <BlogItems posts={posts}/>
      <br/>
      <Reviews/>

     
    </div>
    )
  }

export default Posts