import React, { useState, useEffect } from 'react'
import TitleBar from './TitleBar'
import { getBlends } from '../contentful'
import BlendCard from './BlendCard'

const Blends = (props) => {

    const [blends, setBlends] = useState([])

    useEffect(() => {
        getBlends().then(data => setBlends(data))

    }, [] )


    return (
        <div className="contents">
        <TitleBar title="Bespoke Blends"/>
        { blends.map(blend => <BlendCard blend={blend} /> )}
        </div>
    )
}

export default Blends;