import React from 'react'
import marked from 'marked'
import { Col } from 'react-bootstrap'

const LocationCard = props => {

    const content = marked(props.location.fields.content)
    return(
        <Col md={4}>
        <div className="location-card">
<img style={{maxHeight: '200px', maxWidth:'80%'}}src={props.location.fields.image.fields.file.url} />
      <hr/>
        <section dangerouslySetInnerHTML={{ __html: content}} />
        <a className="button" href={props.location.fields.link} target="_blank">view their website</a>
        </div>
        </Col>
    )
}

export default LocationCard;