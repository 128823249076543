import React, {useState, useEffect} from 'react';
import TitleBar from './TitleBar';
import CoffeeClubCard from './CoffeeClubCard';
import { Row, Col } from 'react-bootstrap';
import {getCoffeeClubDeals} from '../contentful';
import mug from '../assets/mug.jpg';


const CoffeeClub = () => {

const [deals, setDeals] = useState([])

useEffect(() => {
    window.scrollTo(0,0)
    getCoffeeClubDeals().then(data => setDeals(data));
}, [])



    return(
    <div className="contents ">
        <TitleBar title="The Coffee Club" />
<div className="text-light text-center" >
    <p>Welcome to the Coffee Club.  Here you will find our special subscription offers. These include special roasts only available to regular subscribers, as well as discounts on some of our regular products.</p>
    <p>Our Coffee Club subscriptions offer a convenient way to ensure your life stays enriched by Rocsato Coffee, without having to manually place orders when you need them, or worse still, when it's too late!. </p>
    <p>Not only do our subscriptions help with the day to day running of your household or business, they also make great gifts for your coffee-loving loved-ones.</p>

</div>

        <Row>
{deals.map(deal => <CoffeeClubCard deal={deal}/>
)}
   
      
      </Row>
      <div className="text-box text-center">
          <small>Rocsato Coffee Roasters reserves the right to substitute items of a similar monetary value and nature in the event that 
              a product becomes unavailable during the course of the subscription term.
          </small>

      </div>
    </div>)

}

export default CoffeeClub;