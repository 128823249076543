import React from 'react'
import { Link } from 'react-router-dom'
import { FaCoffee, FaShoppingCart, FaPaperPlane } from 'react-icons/fa'

const FootButtons = () => {
    return(<>
        <div className="footer-buttons">
<Link className="buy-button btn btn-dark" to="/store"><FaShoppingCart size={25}/> Buy Online!</Link>
<Link className="buy-button btn btn-dark" to="/coffeeclub"><FaCoffee size={25}/> Coffee Club Subscriptions</Link>
 
        
        
       
        
        </div>
        <div className="footer-buttons">
        <a href="http://eepurl.com/hfG3qn" target="_blank" style={{color:'white'}} className="btn btn-dark"><FaPaperPlane size={25}/>  Subscribe to our mailing-list and stay up to date with our special offers.</a>

        </div>
   </> )
}

export default FootButtons;