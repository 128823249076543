import React, { useEffect } from "react";
import TitleBar from "./TitleBar";
import { Row, Col } from "react-bootstrap";
import house from "../assets/imgs/house.jpg";
import roasting from "../assets/imgs/Roasting1.jpg";

const OurStory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className='contents'>
      <TitleBar title='Our Story' />
      <Row>
        <Col md={6}>
          <div className='text-box'>
            <p>
              Rocsato Coffee Roasters LLC is a Veteran Owned and Operated
              micro-roasting company located in the mountains of Northwestern
              Pennsylvania at Hillhurst Mansion in Titusville, PA.
            </p>
            <p>
              We are a small batch Artisan Roaster with a focus on high quality
              custom roasted coffee delivered to our customers with a personal
              touch. The name Rocsato comes from the combination of two great
              family names brought together by the love of music, coffee, people
              and the love of each other. We pass that love (especially of
              coffee) on to you, our customers, by providing the best possible
              tasting coffee in all of Pennsylvania!
            </p>
          </div>
        </Col>
        <Col md={6}>
          <img style={{ width: "100%" }} src={house} alt='' />
        </Col>
      </Row>
      <div style={{ marginTop: "25px" }}>
        <Row>
          <Col md={8}>
            <div className='text-box'>
              <h4>Meet Greg</h4>
              <h5>A Bit of My Roasting Philosophy</h5>
              <p>
                The essence of the bean. Something that I learned while working
                in production at one of Virginia’s premier and largest
                Vineyards/Wineries. We approached our wine making the same way.
                Why would you make a wine or coffee in this instance, and not
                focus on what makes it a wine or coffee? One cannot have coffee
                without the coffee bean…the seed of the coffee berry. I embrace
                this philosophy in my everyday roasting of any level quality
                bean, from my most common variety to the most specialized bean.
                All my coffees are about the natural characteristics of the
                bean. I try to establish roasting profiles based on those
                characteristics to bring out the essence of the natural flavor
                of each individual bean. Therefore, you will not find any of my
                roasts so dark that there is no color other than black left in
                the bean. My darkest roasts, even my espresso roasts, have a
                brown to dark brown color. With that color, comes more of the
                natural flavor and thus the essence of the bean.
              </p>
              <p>
                My wife Aimé, is Italian and therefore, as we all have come to
                realize with Italians, has a unique and refined palette for the
                finer things in the food world. Her taste in coffee is no
                different. She has a distinctive preference for a creamy
                characteristic in coffee and is a large part of the final flavor
                profiles of my coffees. All of my coffees will have that creamy
                smooth characteristic that we both prefer. When choosing a bean
                to offer on our menu, the bean goes through an extensive vetting
                process from cuppings to “in home” tastings. We try the coffee
                being vetted in our everyday consumption to ensure that we are
                recreating the way our customers will be drinking our coffee.
                This process has worked well thus far because we seem to have
                built a returning customer base with our coffees. We will
                continue to provide the best Artisan Roasted Gourmet Coffee in
                Pennsylvania for the best customers on the planet!
              </p>
            </div>
          </Col>
          <Col md={4}>
            {" "}
            <img style={{ width: "100%" }} src={roasting} />{" "}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OurStory;
