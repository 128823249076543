import React, { Component } from "react";
import Client from "shopify-buy";

const ShopContext = React.createContext();

const client = Client.buildClient({
  domain: "rocsato-coffee-roasters.myshopify.com",
  storefrontAccessToken: process.env.REACT_APP_SHOPIFY,
});

class shopProvider extends Component {
  state = {
    products: [],
    product: {},
    checkout: {},
    isCartOpen: false,
    selectedOptions: '',
    numberOfItems: 0,
    collections: []
  };
 
  componentDidMount() {
    this.createCheckout();
   // check if local storage has a checkout_id saved
// if(localStorage.checkout){
//   this.fetchCheckout(localStorage.checkout)
// } else {
//   this.createCheckout();
// }
  
  }


  fetchCheckout = async (checkoutId) => {
    client.checkout.fetch(checkoutId).then(checkout => {
      console.log(checkout);

      this.setState({checkout: checkout});
    }).catch(err => console.log(err))
  }

  createCheckout = async () => {
    const checkout = await client.checkout.create();
    localStorage.setItem('checkout', checkout.id)
    this.setState({ checkout: checkout });
    // console.log(checkout)
  };

  addItemToCart = async (variantId, quantity) => { 
    const lineItemsToAdd = [
      {
        variantId,
        quantity: parseInt(quantity, 10)
      }];

      const checkout = await client.checkout.addLineItems(
        this.state.checkout.id, lineItemsToAdd)
    this.setState({ checkout: checkout, numberOfItems: this.state.numberOfItems + 1})
console.log(this.state.numberOfItems)
console.log(this.state.checkout)
    
  };


removeLineItemInCart = async ( lineItemId) => {
   const checkout = await client.checkout.removeLineItems(this.state.checkout.id, lineItemId).then(res => {
    //  console.log(res)
    this.setState({ checkout: res, numberOfItems: this.state.numberOfItems - 1 });
  }
  );
  console.log(this.state.checkout)
}

updateQuantity = async (lineItemId, quantity) => {
  const checkoutId = this.state.checkout.id
  const lineItemsToUpdate = [{id: lineItemId, quantity: +quantity}]

  return await client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
    this.setState({
      checkout: res,
    });
    console.log(lineItemsToUpdate)
  });
}

decrementQuantity =  (lineItemId) => {
  const itemId = lineItemId.id
  const updatedQuantity =  lineItemId.quantity - 1
  this.updateQuantity(itemId, updatedQuantity);
  console.log(lineItemId.quantity)
  this.setState({numberOfItems: this.state.numberOfItems - 1})
  console.log(this.state.numberOfItems)
}

incrementQuantity = (lineItemId) => {
  const itemId = lineItemId.id
  const updatedQuantity = lineItemId.quantity + 1
  this.updateQuantity(itemId, updatedQuantity);
  this.setState({numberOfItems: this.state.numberOfItems + 1})
  console.log(this.state.numberOfItems)
}

  fetchProducts = async () => {
    const products = await client.product.fetchAll();
    this.setState({ products: products });
    console.log(products);
    
  };

  fetchSingle = async (id) => {
    const single = await client.product.fetch(id);
    console.log(single)
    this.setState({ product: single });
  
  };

  fetchQuery = async (query) => {
  const data = await client.product.fetchQuery(query);
  this.setState({products: data})
    console.log(data)
  };


  handleOptionChange = async (event) => {
    const target = event.target
    let selectedOptions = this.state.selectedOptions;
    selectedOptions[target.name] = target.value;

    // const selectedVariant = await client.product.helpers.variantForOptions(this.state.product, selectedOptions)
    console.log(target.value)
    // this.setState({
    //   selectedVariant: selectedVariant,
    // });

  }
fetchCollections = async () => {
  // Fetch all collections, including their products
const collections = await client.collection.fetchAllWithProducts()

  console.log(collections[1].attrs);
this.setState({collections: collections[1].products})
}


  closeCart = () => {
    this.setState({ isCartOpen: false });
  };

  openCart = () => {
    this.setState({ isCartOpen: true });
  };

  render() {
    return (
      <ShopContext.Provider
        value={{
          ...this.state,
          fetchProducts: this.fetchProducts,
          fetchSingle: this.fetchSingle,
          closeCart: this.closeCart,
          openCart: this.openCart,
          addItemToCart: this.addItemToCart,
          removeItem: this.removeLineItemInCart,
          updateQuantity: this.updateQuantity,
          decrementQuantity: this.decrementQuantity,
          incrementQuantity: this.incrementQuantity,
          handleOptionChange: this.handleOptionChange,
          fetchCollections: this.fetchCollections,
          fetchQuery: this.fetchQuery,
          collections: this.collections,
          numberOfItems: this.state.numberOfItems
        }}>
        {this.props.children}
      </ShopContext.Provider>
    );
  }
}

const ShopConsumer = ShopContext.Consumer;

export { ShopConsumer, ShopContext };

export default shopProvider;
