import React from "react";
import marked from "marked";
import { Row, Col } from "react-bootstrap";
import logo from '../assets/logo.png'

const CoffeeCornerFull = ({post}) => {


  if(!post) return (
<div className="text-center text-box">
  <img style={{width:'200px', margin:'auto'}} src={logo}/>
  <h4>Welcome to Coffee Corner!</h4>
  Please click on an article to read more.</div>
  )

return <div className="text-box coffee-corner-article">
<Row>
  <Col><div style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}> 
  <h4 style={{textAlign:'center'}}>{post.fields.title}</h4>
  </div>
</Col>
  <Col><img style={{width:'100%'}} src={post.fields.mainImage.fields.file.url}/>
</Col>
</Row>
<div dangerouslySetInnerHTML={{__html: marked(post.fields.article)}} />
 </div>
 

  
};

export default CoffeeCornerFull;
