import { findRenderedDOMComponentWithClass } from "react-dom/test-utils";

const client = require('contentful').createClient({
    space: 'eqi17ekp0izf',
    accessToken: 'MDgA62KZMyizDUGCK968NTQctuyaiX8TcLFyNHTi6ak'
  })
  
  const getBlogPosts = () => client.getEntries({content_type:'post'}).then(response => response.items)
  const getReviews = () => client.getEntries({content_type:'review'}).then(response => response.items)
  const getCoffeeCorner = () => client.getEntries({content_type:'coffeeCorner'}).then(response => response.items)
  const getCoffeeCornerSingle = (slug) => client.getEntries({content_type:'coffeeCorner', 'fields.title': slug }).then(response => response.items)
  const getCoffeeClubDeals = () => client.getEntries({content_type:'coffeeClubDeal'}).then(response => response.items)
  const getBlends = () => client.getEntries({content_type: 'bespokeBlend'}).then(response => response.items)
  const getLocations = () => client.getEntries({content_type:'locations'}).then(response => response.items)
 const getBiog = () => client.getEntries({content_type:'biography'}).then(response => response.items)
  const getGallery = () => client.getEntries({content_type:'galleryPhoto'}).then(response => response.items)
  const getPrivacyPolicy = () => client.getEntries({content_type:'privacyPolicy'}).then(response => response.items)
  
  const getSinglePost = slug => client.getEntries({
        'sys.id': slug, content_type: 'post'}).then(response => response.items)
  
        const getSingleReview = slug => client.getEntries({
          'fields.title': slug, content_type: 'review'}).then(response => response.items)

  export { getCoffeeClubDeals, getPrivacyPolicy, getCoffeeCornerSingle, getCoffeeCorner, getBlends, getReviews, getSingleReview, getGallery, getBlogPosts, getSinglePost,  getLocations,
   getBiog,  }