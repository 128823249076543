import React, { useState, useEffect } from 'react'
import TitleBar from './TitleBar'
import ImageGrid from './ImageGrid'
import Modal from './Modal'
import { getGallery } from '../contentful'





const Gallery = () => {
    const [selectedImg, setSelectedImg] = useState(null)
    const [title, setTitle] = useState(null)
    const [images, setImages] = useState(null)


    useEffect(() => {
        getGallery().then(data => setImages(data))
    }, [])

   return <div>
    <TitleBar title="Photo Gallery"  />
    <ImageGrid images={images} setSelectedImg={setSelectedImg} setTitle={setTitle}/>
    {selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} title={title} setTitle={setTitle} />}
    </div>
};

export default Gallery;