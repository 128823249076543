import React, { useState } from "react";
import {
  FaPaperPlane,
  FaInstagram,
  FaPhoneAlt,
  FaEnvelopeOpen,
} from "react-icons/fa";
import { Row, Col } from "react-bootstrap";
import Privacy from "./Privacy";

const Contact = () => {
  const [modal, setModal] = useState(false);

  return (
    <>
      <div className='contents'>
        <div className='contact text-box'>
          <h4> Contact Us</h4>
          <Row>
            <Col md={6}>
              {" "}
              <div className='contact-card'>
                {" "}
                <a href='mailto:rocsatocoffeeroasters@gmail.com'>
                  <FaEnvelopeOpen /> <p>rocsatocoffeeroasters@gmail.com</p>
                </a>
              </div>
            </Col>
            <Col md={6}>
              {" "}
              <div className='contact-card'>
                {" "}
                <a href='tel:5404933827'>
                  {" "}
                  <FaPhoneAlt /> <p>(540) 493-3827</p>
                </a>
              </div>
            </Col>
            <Col md={6}>
              {" "}
              <div className='contact-card'>
                {" "}
                <a href='http://eepurl.com/hfG3qn'>
                  {" "}
                  <FaPaperPlane />{" "}
                  <p>
                    Don't forget to sign up to our mailing list to keep up to
                    date with new products and special offers.
                  </p>
                </a>
                <small>
                  <a onClick={() => setModal(true)} href='#'>
                    {" "}
                    view our privacy policy
                  </a>
                </small>
                <Privacy open={modal} close={() => setModal(false)} />
              </div>
            </Col>
            <Col md={6}>
              {" "}
              <div className='contact-card'>
                {" "}
                <a href='https://www.instagram.com/rocsatocoffeeroasters/'>
                  {" "}
                  <FaInstagram />
                  <p>You can also follow us on Instagram</p>
                </a>
              </div>
            </Col>
          </Row>
          <hr />
          <h5>Come visit!</h5>
          <p>
            701 N Perry Street, Titusville, Pennsylvania 16354. We are open for
            drop-ins, by appointment. Please contact us to schedule a time.
          </p>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2981.9488619000686!2d-79.68025034859077!3d41.635236779140385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8832c315d79d5e53%3A0x585767240140e010!2s701%20N%20Perry%20St%2C%20Titusville%2C%20PA%2016354%2C%20USA!5e0!3m2!1sen!2ses!4v1642442759311!5m2!1sen!2ses'
            width='600'
            height='450'
            style={{ border: 0 }}
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Contact;
