import React, { useEffect } from 'react'
import BlogItem from './BlogItem'
import { Row, Col } from 'react-bootstrap'



const BlogItems = ({posts}) => {
 
  useEffect(() => {
    window.scrollTo(0,0)
   }, [])
   
 return(
 <div className=" content">
    <Row>
    {posts.map((article, index) => 
  <BlogItem article={article} key={index} />)}
  </Row>
  </div>
)
    }
export default BlogItems