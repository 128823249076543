import React, { useState, useEffect } from 'react'
import marked from 'marked'
import { getReviews } from '../contentful'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Reviews = () => {
    const [posts, setPosts] = useState([])

    useEffect(() => {
        getReviews().then(data => setPosts(data))
    }, [])


   return(
            <div>
                <h3 style={{color: 'rgb(240, 228, 202)'}}>Customer Reviews</h3>
                <Row>
                {posts.map(post => {
                    const content = marked(post.fields.body)
                    const excerpt = content.split(' ').slice(0, 35).join(' ') + '...'
                return (<Col md={4}>
                <div className="text-box">
                    <h5>{post.fields.title}</h5>
                    <section dangerouslySetInnerHTML={{__html: excerpt}}></section>
                    <Link to={'/posts/reviews/' + post.fields.title} >Read more...</Link>

                </div>
                    
                    
                    
                    
                    </Col>)
                }
                    )}
                </Row>
            </div>
        )
    
}

export default Reviews;
