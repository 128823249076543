import React, { Component } from "react";
import {
  FaTwitter,
  FaShoppingCart,
  FaYoutube,
  FaSpotify,
  FaFacebook,
} from "react-icons/fa";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import "./Toolbar.css";
import DrawerToggleButton from "../DrawerToggleButton/DrawerToggleButton";

import { ShopContext } from "../../context/ShopContext";

class toolbar extends Component {
  constructor(props) {
    super(props);
  }

  static contextType = ShopContext;

  render() {
    return (
      <header className="toolbar">
        <nav className="toolbar__navigation">
          <div className="toolbar__toggle-button">
            <DrawerToggleButton click={this.props.drawerClickHandler} />
          </div>

          <div className="header">
            <ul>
              {/* <Link>Contact Us</Link> */}

              <Link to="/">
                <img src={logo} alt="" />
              </Link>
              {/* <Link>Online Store</Link> */}
            </ul>
          </div>
          <div className="spacer" />
          <div className="toolbar_navigation-items">
            <Link to="/store">
              <div className="cart-btn-wrapper">
                {" "}
                <FaShoppingCart size={25} onClick={this.context.openCart} />
                <span style={{ marginLeft: "3px" }}>
                  {this.context.numberOfItems}
                </span>
              </div>{" "}
            </Link>
            {/* <a href="https://www.facebook.com/lepavillondemusique/" target="_blank">
          <FaFacebook size={35} />
        </a> */}

            {/*<li>
            <a href="https://twitter.com/TabeaDebus" target="_blank"><FaTwitter/></a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCIwHWkZF447ssRHDaTpWJpg" target="_blank"><FaYoutube/></a>
          </li>
          <li>
            <a href="https://open.spotify.com/artist/1MbLfjWZWdE2dt3yOJnU4e?si=Pz6mmUhSTG2Nwwu8G5Xlrg" target="_blank"><FaSpotify/></a>
          </li> */}
          </div>
        </nav>
      </header>
    );
  }
}
export default toolbar;
