import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, NavItem, NavLink } from "react-bootstrap";
import logo from "../assets/logo.png";
import { FaInstagram, FaFacebook, FaShoppingCart } from "react-icons/fa";
import { ShopContext } from '../context/ShopContext'

class Nav extends Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
    };
  }

 static contextType = ShopContext

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }
  render() {
    const collapsed = this.state.collapsed;
    const classOne = collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show";
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right";
    return (
      <div>
        <Link to="/">
          <div className="header">
            <img style={{ height: "150px" }} src={logo} />
            <small>
              Artisan Roasted Gourmet Coffee, Paired Perfectly with Your Taste
            </small>
          </div>
        </Link>

        <nav className=" navbar-expand-lg navbar-dark bg-dark">
          <div className="container ">
            <button
              onClick={this.toggleNavbar}
              className={`${classTwo}`}
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className={`${classOne}`} id="navbarResponsive">
              <ul className="navbar-nav m-auto text-nowrap">
               
                

                {/* <Dropdown >
                  <Dropdown.Toggle as={NavLink} className="dropdown">About Us</Dropdown.Toggle>
                  <Dropdown.Menu>
                  <Dropdown.Item>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/ourstory">Our Story</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/roaster">Our Roaster</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                    <Link className="nav-link" to="/locations">
                    Where to Find Our Coffee
                  </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                 <li className="nav-item">
                <Link className="nav-link" to="/ourstory">Our Story</Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link" to="/coffeecorner">Coffee Corner: Brewing Tips & Recipes</Link>
                </li>
                {/* <Dropdown as={NavItem}>
                  <Dropdown.Toggle as={NavLink}>The Coffee Club</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      {" "}
                      <Link to="/coffeecorner">Coffee Corner: Brewing Tips & Recipes</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      {" "}
                      <Link to="/coffeeclub">The Coffee Club Subscription</Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                
                <Dropdown as={NavItem}>
                  <Dropdown.Toggle as={NavLink}>Our Coffees</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      {" "}
                      <Link to="/store">Our Range</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      {" "}
                      <Link to="/blends">Bespoke Blends</Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <li className="nav-item">
                  <Link className="nav-link" to="/posts">
                    News & Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/store">
                    Store
                  </Link>
                </li>
                <div className="cart-btn-wrapper">
                <li className="cart-btn">
                <FaShoppingCart onClick={this.context.openCart}/><small style={{marginLeft:'3px'}} >{this.context.numberOfItems}</small>
                </li>
                </div>
              </ul>
              <div className="social-links ml-auto">
                <a href="https://www.instagram.com/rocsatocoffeeroasters/">
                  {" "}
                  <FaInstagram size={20} />
                </a>
                <a href="https://www.facebook.com/rocsatocoffeeroasters">
                  {" "}
                  <FaFacebook size={20} />
                </a>

              </div>
            
             
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
export default Nav;
