import React, { useState, useEffect } from "react";
import { getSinglePost } from "../contentful";
import { FaTwitter, FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";

import SinglePostDisplay from "./SinglePostDisplay";

const SinglePost = (props) => {
  const [post, setPost] = useState([]);

  useEffect(() => {
    getSinglePost(props.match.params.id).then((data) => setPost(data));
  }, []);

  return (
    <div>
      {post.map((post, i) => (
        <SinglePostDisplay key={i} post={post} />
      ))}
    </div>
  );
};

export default SinglePost;
