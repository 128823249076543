import React from "react";
import marked from "marked";
import { Link } from "react-router-dom";
import { FaTwitter, FaFacebook, FaInstagram } from "react-icons/fa";
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  FacebookShareButton,
} from "react-share";
import { Col } from "react-bootstrap";
const imageStyle = {
  width: "100%",
};

const BlogItem = ({ article }) => {
  console.log(article);
  const { title, image, author, body, slug } = article.fields || {};
  const postBody = body ? marked(body) : "";
  const excerpt = postBody.split(" ").splice(0, 40).join(" ");

  return (
    <Col md={4}>
      <div className='post-card text-box'>
        <div>
          {image && <img style={imageStyle} src={image?.fields?.file.url} />}

          <h5>{title}</h5>
          <div className='meta'>
            <p>{author && <em>{author.fields.name}</em>}</p>
            <p>
              {author && (
                <a href={author.fields.facebook} target='_blank'>
                  <FaFacebook className='social' />
                </a>
              )}
            </p>
            <p>
              {author && (
                <a href={author.fields.instagram} target='_blank'>
                  <FaInstagram className='social' />
                </a>
              )}
            </p>
          </div>
          <section
            className='blog-post'
            dangerouslySetInnerHTML={{ __html: excerpt + "..." }}
          />
        </div>
      </div>
      <div>
        <Link className='read-more' to={"/posts/" + article.sys.id}>
          Read more...
        </Link>
      </div>
    </Col>
  );
};

export default BlogItem;
