import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ShopContext } from "../context/ShopContext";
import { Row, Col } from "react-bootstrap";
import { FaShoppingCart, FaArrowLeft } from "react-icons/fa";

const Product = () => {
  const { fetchSingle, addItemToCart, product, openCart } = useContext(
    ShopContext
  );

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const id = window.location.pathname.split("product/")[1];
    fetchSingle(id);
  }, [fetchSingle]);

  if (!product) {
    return <h3>Loading...</h3>;
  }

  const optionSelect = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className='contents text-box'>
      <div style={{ marginTop: "25px", height: "25px" }}></div>
      <Link to='/store' className='btn btn-dark'>
        <FaArrowLeft /> Back to all products
      </Link>
      <button className='btn btn-dark' onClick={openCart}>
        <FaShoppingCart />
      </button>

      <Row>
        <Col md={4}>
          {product.images && (
            <img style={{ width: "100%" }} src={product.images[0].src} />
          )}
        </Col>
        <Col md={8}>
          <h5>{product.title}</h5>
          <p>{product.description}</p>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              alignItems: "start",
            }}
          >
            {product.variants && (
              <select onChange={optionSelect}>
                <option value='none'>Please Select</option>
                {product.variants.map((option) => (
                  <option value={option.id}>
                    {option.title} : ${option.price.amount}
                  </option>
                ))}{" "}
              </select>
            )}
            <button
              disabled={selectedOption === "none" || !selectedOption}
              style={{ marginTop: "15px" }}
              className='btn btn-dark'
              onClick={() => {
                addItemToCart(selectedOption, 1);
                openCart();
              }}
            >
              {selectedOption === "none" || !selectedOption
                ? "Please select an option"
                : "Add To Cart"}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Product;
