import React from 'react'
import Event from './Event'

class Calendar extends React.Component {
  state = {
    posts: []
  }

  render() {
    return (
      
        <div className='contents'>
            <h1>Calendar</h1>
    {this.state.posts.map((item) => 
   <Event date={item} />
    )}
      <br/>
     
    </div>
    )
  }
}
export default Calendar