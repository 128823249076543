import React from "react";
// JSX
import HeroSlider, { Slide, Nav, OverlayContainer } from "hero-slider";

// Images
import david1 from "../assets/imgs/header1.jpg";
import david2 from "../assets/imgs/header2.jpg";
import david3 from "../assets/imgs/header3.jpg";

const slider = () => {
  return (
    <HeroSlider
      slidingAnimation='fade'
      orientation='horizontal'
      initialSlide={1}
      onBeforeChange={(previousSlide, nextSlide) =>
        console.log("onBeforeChange", previousSlide, nextSlide)
      }
      onChange={(nextSlide) => console.log("onChange", nextSlide)}
      onAfterChange={(nextSlide) => console.log("onAfterChange", nextSlide)}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.3)",
      }}
      settings={{
        slidingDuration: 1200,
        slidingDelay: 700,
        shouldAutoplay: true,
        shouldDisplayButtons: false,
        autoplayDuration: 10000,
        height: "50vh",
      }}
    >
      <OverlayContainer>
        <div className='slider-text'>
          <h2>Welcome to</h2>
          <h1>Rocsato Coffee Roasters</h1>
          <p>Artisanally roasted, gourmet coffee from Pennsylvania</p>
        </div>
      </OverlayContainer>

      <Slide
        className='slider-background'
        background={{
          backgroundImage:
            "https://media.giphy.com/media/Q6joirtIBHUsw/giphy.gif",
          // backgroundAttachment: "fixed",
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      />

      <Slide
        className='slider-background'
        background={{
          backgroundImage: david3,
          backgroundSize: "cover",
          backgroundPosition: "top",

          // backgroundAttachment: "fixed"
        }}
      />

      <Slide
        className='slider-background'
        background={{
          backgroundImage: david2,
          // backgroundAttachment: "fixed",
          backgroundPosition: "left",
        }}
      />

      <Nav />
    </HeroSlider>
  );
};

export default slider;
