import React, { useState, useEffect } from 'react'
import marked from 'marked'
import TitleBar from './TitleBar'
import { getSingleReview } from '../contentful'
import {
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    FacebookIcon,
    FacebookShareButton,
  } from "react-share";
  import { Link } from "react-router-dom";

const Review = (props) => {

const [ review, setReview ] = useState([])

useEffect(() => {
  window.scrollTo(0,0)
    getSingleReview(props.match.params.id).then(data => setReview(data))
}, [])

    return (
        <div className="contents">
            {review.map(review => {
                const content = marked(review.fields.body)

                return (
                    <div className="text-box">
                        <h4>{review.fields.title}</h4>
                        <section dangerouslySetInnerHTML={{__html: content}}></section>
                   
                        <small>share on social media: </small>

<TwitterShareButton
  url={window.location}
  children={<TwitterIcon size={24} />}
/>
<LinkedinShareButton
  url={window.location}
  children={<LinkedinIcon size={24} />}
/>
<FacebookShareButton
  url={window.location}
  children={<FacebookIcon size={24} />}
/>

<div>
  <Link to="/posts/">back</Link>
</div>
                    </div>
                )
            })}
        </div>
    )
}

export default Review;