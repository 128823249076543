import React from 'react'
import marked from 'marked'

const Event = (props) => {

    const details = marked(props.date.fields.description)

    const date = new Date(props.date.fields.date)
    return <div className="event">
        <hr></hr>
        <h5>{props.date.fields.title}</h5>
        <p>{date.toDateString()}</p>
<p>{date.getHours()}:{date.getMinutes()}</p>
        <p>{props.date.fields.venue}</p>
        {details ? 
        <section dangerouslySetInnerHTML={{ __html: details}}/> : null}
        <hr></hr>
    </div>
}

export default Event
