import React from "react";
import { Row, Col } from "react-bootstrap";
import marked from "marked";
import paypal from '../assets/imgs/paypal-logo.jpeg';

const CoffeeClubCard = ({deal}) => {

    let text = marked(deal.fields.description)
    let text2;
    if(deal.fields.extraText){text2 = marked(deal.fields.extraText)}
  return (
    <Col>
      <div className="text-box">
        <Row>
          <Col md={8}>
            <h4>{deal.fields.title}</h4>
            <h5>{deal.fields.price}</h5>
            <section dangerouslySetInnerHTML={{__html: text}} />   
            <hr/> 
           {deal.fields.extraText &&
          
            <Row>
              
              <Col xs={8}>
                <div style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
              <section style={{textAlign:'center'}} dangerouslySetInnerHTML={{__html: text2}}/>
              </div>
              </Col>
              <Col xs={4}>
              <img src={deal.fields.extraImage.fields.file.url} style={{width:'100%'}}/>
                </Col>
              </Row>  
}    

            <a
              className="btn btn-dark"
              href={deal.fields.buttonLink}
              target="_blank"
            >
              Subscribe now!
            </a><br/>
            <small>Payment by credit or debit card</small><br/>
            <small>Powered by Paypal</small><br/>
            <img style={{width:'70px'}} src={paypal} />
          </Col>
          <Col md={4}>
            <img src={deal.fields.image.fields.file.url} style={{width:'100%'}} />
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default CoffeeClubCard;
