import React, {Component} from 'react'
import marked from 'marked'
import { getBiog } from '../contentful'

class BiogFull extends Component {
    state = {
        biog: ''
      }
    
      getMarkdownText() {
        var rawMarkup = marked(this.state.biog);
        return { __html: rawMarkup };
      }

   componentDidMount(){
     getBiog().then(data => this.setState({biog: data[0].fields.biography}))
   }
     

    render(){

    return <div className="contents">
        <h4>Biography</h4>
        <div dangerouslySetInnerHTML={this.getMarkdownText()} />

         </div>
}
}

export default BiogFull