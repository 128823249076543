import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaShoppingCart, FaInstagram} from "react-icons/fa";
import { Dropdown, NavLink, NavItem } from "react-bootstrap";
import DrawerToggleButton from '../DrawerToggleButton/DrawerToggleButton'

import logo from "../../assets/logo.png";

import "./SideDrawer.css";


const sideDrawer = (props) => {
  
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }
  return (
    <div>
      <nav className={drawerClasses}>
         
    

      <div style={{padding: '15px'}} className="toolbar__toggle-button">
          <DrawerToggleButton click={props.click} />
          </div>
        <ul>
       <li> 
       <Link  onClick={props.click} to="/">
            <img style={{height:'130px', marginBottom:'30px'}} src={logo} alt=""/>
          </Link>
          </li>
       <li>
                <Link onClick={props.click} to="/store">
                  Buy Online</Link>
                </li>
                <li>
                <Link onClick={props.click} to="/coffeeclub">
              Coffee Club Subscriptions               
              </Link>
                </li>
                <li>
                <Link onClick={props.click} to="/ourstory">
                 Our Story
                </Link>
                </li>
            
                <li>
                <Link onClick={props.click} to="/store">
                  Our Coffees</Link>
                </li>

                <li>
                <Link onClick={props.click} to="/blends">
                  Bespoke Blends</Link>
                </li>
                <li>
                <Link onClick={props.click} to="/coffeecorner">
                  Coffee Corner: Brewing Tips & Recipes</Link>
                </li>

                <li>
                <Link onClick={props.click} to="/posts">
                  News & Blog</Link>
                </li>
                <li>
                <Link onClick={props.click} to="/contact">
                  Contact</Link>
                </li>
                <li>
                <Link onClick={props.click} to="/store">
                <FaShoppingCart/>
</Link>
                </li>
            
                
              
                
        </ul>
        <div className="social">
        <a href="https://www.instagram.com/rocsatocoffeeroasters/">
                  {" "}
                  <FaInstagram size={35} />
                </a>
                <a href="https://www.facebook.com/rocsatocoffeeroasters">
                  {" "}
                  <FaFacebook size={35} />
                </a>         </div>{/*
            <a href="https://twitter.com/TabeaDebus" target="_blank"><FaTwitter size={35}/></a>
      
            <a href="https://www.youtube.com/channel/UCIwHWkZF447ssRHDaTpWJpg" target="_blank"><FaYoutube size={35}/></a>
      
            <a href="https://open.spotify.com/artist/1MbLfjWZWdE2dt3yOJnU4e?si=Pz6mmUhSTG2Nwwu8G5Xlrg" target="_blank"><FaSpotify size={35}/></a>
        </div> */}
        <div className="i18n">
        </div>

      </nav>
    </div>
  );
};

export default sideDrawer;
