import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';


const ImageGrid = (props) => {



  return (
    <div className="img-grid">
      {props.images && props.images.map(image => (
          <div>
        <motion.div className="img-wrap" key={image.url} 
          layout
          whileHover={{ opacity: 1 }}s
          onClick={() => {
            props.setSelectedImg(image.fields.image.fields.file.url)
            // props.setTitle(image.fields.title)
        }
        }
        >
        

          <motion.img src={image.fields.image.fields.file.url} alt="uploaded pic"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
          />

        </motion.div>
        {/* {image.fields.title && <motion.p  initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }} >{image.fields.title}</motion.p>} */}

        </div>
      ))}
    </div>
  )
}

export default ImageGrid;