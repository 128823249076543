import React, { useState } from "react";
import firebase from "../firebase";

const style = {
  minHeight: "60px",
  color: "white",
  background: "black",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const Footer = (props) => {
  return (
    <div style={style}>
      <p>
        Another fast, secure, modern website designed and developed by{" "}
        <a style={{ color: "white" }} href='https://www.webspinner.eu'>
          WebSpinner.eu
        </a>
      </p>
    </div>
  );
};

export default Footer;
