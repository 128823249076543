import React, { useState, useEffect, useContext } from 'react'
import TitleBar from './TitleBar'
import { getCoffeeCorner } from '../contentful'
import marked from 'marked'
import CoffeeCornerFull from './CoffeeCornerFull'
import { Row, Col } from 'react-bootstrap'
import { Link, Route } from 'react-router-dom'
import { ShopContext } from '../context/ShopContext'

const CoffeeCorner = () => {

    const [posts, setPosts] = useState([])
    const [selectedPost, setSelectedPost] = useState(posts[0])

    const { openCart } = useContext(ShopContext)

    useEffect(() => {
        window.scrollTo(0, 0)
        getCoffeeCorner().then(data => setPosts(data))

    }, []
    )

   const  handlePostSelect = (id) => {
        setSelectedPost(posts.filter(a => a.sys.id === id)[0])

    }

    return(
        <div className="contents">
<TitleBar title="Coffee Corner" />
<div className="light-text-bullets coffee-corner-header">
    <div>
<p>Brewing tips and serving suggestions.</p>
<p>Inspiration and ideas...</p>
<p>...or just something to read which you enjoy your <a href="#" onClick={openCart} > Rocsato Coffee </a></p>
</div>
<div>
<img src="https://media.giphy.com/media/Q6joirtIBHUsw/giphy.gif"/>
</div>
</div>

<Row>
<Col md={4}>
    <div className="coffee-corner-list">
{posts.map(post => {
const article = marked(post.fields.article)
const excerpt = article.split(' ').slice(0, 60).join(' ') + '...'

return(
    <div onClick={() => handlePostSelect(post.sys.id)} key={post.fields.title} className="text-box list">
       {post.fields.mainImage && <img style={{width: '100%', marginBottom:'15px'}} src={post.fields.mainImage.fields.file.url}/>}
        <h5>{post.fields.title}</h5>
        {/* <section dangerouslySetInnerHTML={{__html: excerpt}} /> */}

    </div>
)

})}
</div>
</Col>
<Col md={8}>
<CoffeeCornerFull post={selectedPost}/>
</Col>


</Row>




        </div>
    )

}

export default CoffeeCorner;