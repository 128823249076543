import React, { useState, useEffect } from "react";
import marked from "marked";
import { Link } from "react-router-dom";
import TitleBar from "./TitleBar";
import { Row, Col } from "react-bootstrap";
import firebase from "../firebase";
import dompurify from "dompurify";
import useSendMail from "../useSendMail";

const SinglePostDisplay = ({ post }) => {
  const content = post?.fields?.body ? marked(post?.fields?.body) : "";
  const [author, setAuthor] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const { sendMail } = useSendMail();

  const postComment = async (e) => {
    e.preventDefault();
    const newComment = {
      author,
      email,
      comment,
      date: new Date().toDateString(),
      postId: post.sys.id,
      commentId: new Date().getMilliseconds(),
    };
    sendMail(
      `<div>${author} at ${email} just commented on your post "${post.fields.title}":</br></br> ${comment}</div>`,
      "New comment has been posted on RocsatoCoffeeRoasters.com"
    );
    // Send to Firebase
    await firebase.database().ref(`coffee_comments`).push(newComment);
    setAuthor("");
    setEmail("");
    setComment("");
  };

  useEffect(() => {
    firebase
      .database()
      .ref("coffee_comments")
      .on("value", (snapshot) => {
        const posts = snapshot.val();

        let keys = [];
        if (posts) {
          keys = Object.keys(posts);
        }

        let array = [];

        for (let i = 0; i < keys.length; i++) {
          let k = keys[i];
          let comment = posts[k].comment;
          let author = posts[k].author;
          let date = posts[k].date;
          let postId = posts[k].postId;
          let commentId = posts[k].commentId;

          array.push({ id: k, comment, author, date, postId, commentId });
        }
        setComments(array.filter((comment) => comment.postId === post.sys.id));
      });
  }, []);

  const [adminEmail, setAdminEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);

  const signIn = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(adminEmail, password)
      .then((user) => {
        setUser(user);

        setAdminEmail("");
        setPassword("");
      })
      .catch((error) => {
        let errorMessage = error.message;
        console.log(errorMessage);

        setAdminEmail("");
        setPassword("");
      });
  };

  const deleteComment = (comment) => {
    const array = comments;
    const filtered = array.filter((date) => date.id !== comment.id);
    setComments(filtered);
    firebase.database().ref(`coffee_comments/${comment.id}`).set({});
  };

  const signout = () => {
    firebase.auth().signOut();
  };

  const authListener = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  };

  useEffect(() => {
    authListener();
  }, []);

  return (
    <div style={{ paddingTop: 150 }}>
      <div className='title-box'>
        <TitleBar title={post.fields.title} />
      </div>
      <div className='blog-post content text-box'>
        <Row>
          <Col md={8}>
            {" "}
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Col>
          <Col md={4}>
            {" "}
            {post.fields.image ? (
              <img
                style={{ width: "200px", height: "150px" }}
                src={post.fields.image?.fields?.file?.url}
              />
            ) : null}
            <div>
              <h4>Comments</h4>

              {comments.map((c, i) => (
                <div key={i} className='comment-box'>
                  <p
                    style={{ fontStyle: "italic" }}
                    dangerouslySetInnerHTML={{
                      __html: dompurify.sanitize(c.comment, {
                        FORCE_BODY: true,
                      }),
                    }}
                  />
                  <p style={{ marginBottom: -5 }}>
                    by: <a href={`mailto:${c.email}`}>{c.author}</a>
                  </p>
                  <small>posted: {c.date}</small>
                  {user && (
                    <small
                      style={{
                        color: "red",
                        float: "right",
                        cursor: "pointer",
                      }}
                      onClick={() => deleteComment(c)}
                    >
                      DELETE
                    </small>
                  )}
                </div>
              ))}
              <h5 style={{ marginTop: 20 }}>Add a comment</h5>
              <div>
                <form className='comments-form' onSubmit={postComment}>
                  <input
                    required
                    type='text'
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                    placeholder='name'
                  />
                  <input
                    required
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='email address'
                  />
                  <textarea
                    rows={5}
                    required
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder='enter comment here'
                  />
                  <button type='submit' className='comment-submit-button'>
                    Add
                  </button>
                </form>
              </div>
              <div className='admin-form '>
                {!user && (
                  <>
                    {" "}
                    <input
                      value={adminEmail}
                      onChange={(e) => setAdminEmail(e.target.value)}
                      placeholder='admin email address'
                    />
                    <input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder='password'
                    />{" "}
                  </>
                )}
                <button
                  className='comment-submit-button'
                  onClick={!user ? signIn : signout}
                >
                  {!user ? "admin" : "logout"}
                </button>
              </div>
            </div>
          </Col>
        </Row>

        <div>
          <Link to='/posts/'>back</Link>
        </div>
      </div>
    </div>
  );
};

export default SinglePostDisplay;
