import React, { useState, useEffect, useContext } from "react";
import "../styles/stylesheet.css";
import Hero from "./Hero";
import marked from "marked";
import {
  getBiog,
  getCoffeeCorner,
  getReviews,
  getBlogPosts,
} from "../contentful";
import TitleBar from "./TitleBar";
import { Link } from "react-router-dom";

import { Row, Col } from "react-bootstrap";
import { ShopContext } from "../context/ShopContext";
import { FaCoffee } from "react-icons/fa";

const Main = () => {
  const [biog, setBiog] = useState("");
  const [coffeeCorner, setCoffeeCorner] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [news, setNews] = useState([]);
  const { fetchProducts, products } = useContext(ShopContext);

  useEffect(() => {
    getBlogPosts().then((data) => setNews(data));
    getBiog().then((data) => setBiog(data));
    getCoffeeCorner().then((data) => setCoffeeCorner(data));
    getReviews().then((data) => setReviews(data));
    fetchProducts();
    return () => {};
  }, [fetchProducts]);

  return (
    <div>
      <Hero />
      <div className='contents'>
        {/* <TitleBar title="Welcome" /> */}

        <div className='text-box'>
          <p>
            Rocsato Coffee Roasters LLC is a Veteran Owned and Operated
            micro-roasting company located in the mountains of Northwestern
            Pennsylvania at Hillhurst Mansion in Titusville, PA.
          </p>
          <p>
            We are a small batch Artisan Roaster with a focus on high quality
            custom roasted coffee delivered to our customers with a personal
            touch. The name Rocsato comes from the combination of two great
            family names brought together by the love of music, coffee, people
            and the love of each other. We pass that love (especially of coffee)
            on to you, our customers, by providing the best possible tasting
            coffee in all of Pennsylvania!
          </p>
        </div>

        <div>
          <h3>Products</h3>
          <div className='text-box text-center'>
            <p>
              Scroll through our products and click on a product to find out
              more and buy online!
            </p>
            <p>
              Or use the button below to view and purchase our complete range in
              the online store.
            </p>
            <Link to='/coffeeclub' className='btn btn-dark'>
              You can also check out our <em> subscription offers </em>in the
              Coffee Club <FaCoffee />
            </Link>

            <div className='product-list text-box'>
              {products.map((product) => {
                return (
                  <div className='product-card'>
                    <Link to={"/product/" + product.id}>
                      <div className='text-box'>
                        <img
                          style={{ width: "100%" }}
                          src={product.images[0].src}
                          alt=''
                        />
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>

            <Link className='btn btn-dark' to='/store'>
              To see our full range, click here to visit the store...
            </Link>
          </div>
        </div>
        <Row>
          <Col md={8}>
            <div>
              <Link to='/posts'>
                <h3>Coffee News</h3>
              </Link>
              <Row>
                {news.slice(0, 2).map((news) => {
                  const content = marked(news.fields.body);
                  const excerpt =
                    content.split(" ").slice(0, 25).join(" ") + "...";
                  return (
                    <Col>
                      <Link to={"/posts/" + news.sys.id}>
                        <div className='text-box'>
                          {news.fields.image && (
                            <img
                              style={{ width: "100%" }}
                              src={news.fields.image.fields.file.url}
                            />
                          )}
                          <h5>{news.fields.title}</h5>
                          <section
                            dangerouslySetInnerHTML={{ __html: excerpt }}
                          ></section>
                          Read more...
                        </div>{" "}
                      </Link>{" "}
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Col>
          <Col md={4}>
            <div>
              <h3>Customer Reviews</h3>
              {reviews.slice(0, 6).map((review) => {
                let body = marked(review.fields.body);

                return (
                  <div className='text-box'>
                    <p>
                      <em>{review.fields.title}</em>
                    </p>
                  </div>
                );
              })}

              <Link className='btn btn-dark' to='/posts'>
                read all reviews >
              </Link>
            </div>
          </Col>
        </Row>

        <div>
          <Link to='/coffeecorner'>
            <h3>Coffee Corner</h3>
          </Link>
          <Row>
            {coffeeCorner.slice(0, 4).map((post) => {
              return (
                <Col md={4} key={post.fields.title}>
                  <Link to='/coffeecorner/'>
                    <div className='text-box'>
                      <h5>{post.fields.title}</h5>
                      {post.fields.mainImage && (
                        <img
                          src={post.fields.mainImage.fields.file.url}
                          style={{ width: "100%" }}
                          alt=''
                        />
                      )}
                    </div>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Main;
