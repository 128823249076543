import React, { useState, useEffect } from "react";
import { getLocations } from "../contentful";
import LocationCard from "./LocationCard";
import { Row } from'react-bootstrap';
import TitleBar from './TitleBar'

const Locations = (props) => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    getLocations().then((data) => setLocations(data));
  });

  return (
    <div className="contents">
<TitleBar title="Where to Find Our Coffee" />
        <Row>
      {locations.map((location) => (
        <LocationCard location={location} />
      ))}
      </Row>
    </div>
  );
};

export default Locations;
