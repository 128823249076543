import React from 'react'
import { Row, Col } from 'react-bootstrap'

const BlendCard = props => {
    return(
        <div className="blend-card">
            <Row>
                <Col md={8}> <h5>{props.blend.fields.name}</h5>
    <p>{props.blend.fields.description}</p></Col>
                <Col md={4}>
                <img style={{height:'200px'}} src={props.blend.fields.image.fields.file.url} alt=""/>
                </Col>
            </Row>
           
        </div>
    )

}
export default BlendCard;
