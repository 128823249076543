import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaShoppingCart } from 'react-icons/fa'
import { ShopContext } from '../context/ShopContext'
import  TitleBar  from '../components/TitleBar'

const Store = () => {

  const { fetchProducts, fetchQuery, fetchCollections, products, openCart, addItemToCart, handleOptionChange, numberOfItems } = useContext(ShopContext) ;
  const [selectedOption, setSelectedOption]  = useState(null)

  const optionSelect = (event) => {
    setSelectedOption(event.target.value)
  }

  useEffect(() => {
    window.scrollTo(0,0)
    fetchProducts();
    return () => {};
  }, [fetchProducts]);

const setFilter = (event) => {
  if(event.target.value === 'all'){
    fetchProducts()
  } else {
  let query = {query: `tag:[${event.target.value}]`}
    fetchQuery(query)
  }
}

  if (!products) return <div>Loading...</div>;
  return (
    <div className="contents">
<TitleBar title="Welcome to The Store" />
<div className="store-buttons">
        <button className="btn btn-dark" onClick={openCart}><FaShoppingCart/> <small style={{color:'rgb(160, 137, 119)'}}>{numberOfItems}</small> </button>
      {/* <select onChange={setFilter} >
        <option value="all">All</option>
        <option value="blend">Blends</option>
        <option value="singleO">Single</option>
        <option value="decaf">Decaf</option>
      </select> */}
{/*       
      <input type="radio" value="all" name="filter" onClick={setFilter}/>
      <input type="radio" value="blend" name="filter" onClick={setFilter}/>
      <input type="radio" value="singleO" name="filter" onClick={setFilter}/>
      <input type="radio" value="decaf" name="filter" onClick={setFilter}/>
       */}
<h5>sort by type:</h5>
      <button className="btn btn-dark" onClick={setFilter} value="all" >All</button>
      <button className="btn btn-dark" onClick={setFilter} value="blend" >Blends</button>
      <button className="btn btn-dark" onClick={setFilter} value="singleO" >Single Origin</button>
      <button className="btn btn-dark" onClick={setFilter} value="decaf" >Decaf.</button>
      </div>
      <Row>
        {products.map((product) => {
          return (
            <Col md={4} lg={3} sm={6} xs={6} key={product.id}>
                <div className="coffee-card text-center">
                 {product.images && <Link to={'/product/' + product.id}> <img  src={product.images[0].src} /></Link>} 

          {/* <h6>{product.title}</h6> */}
                {/* { product.variants.length === 1 ?
                   <div> <p>${product.variants[0].price}</p>  <button  className="btn btn-dark" onClick={() => {
                      addItemToCart(product.variants[0].id, 1)
                      openCart()
                  }
                  }>Add To Cart</button> </div> : null
                } */}
                    {/* <div>
                   {product.variants.length > 1 ? <div> <select className="option-dropdown" onChange={optionSelect}>
                     <option value="none">Please Select</option>
                    {product.variants.map(option => 
                        <option value={option.id}>{option.title} : ${option.price}
                        </option>)}
                    </select> 
                    <button disabled={!selectedOption || selectedOption === 'none' } className="btn btn-dark" onClick={() => {
                        addItemToCart(selectedOption, 1)
                        openCart()
                    }
                    }>{ !selectedOption || selectedOption === 'none' ? 'Please select an option' : 'Add To Cart'}</button> </div>: null }
                    </div> */}
                    <div >
                    <Link className="btn btn-dark coffee-button" to={"/product/" + product.id}>find out more... </Link>


                    



                    </div>

                </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Store;
